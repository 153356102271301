import React, { Component } from 'react'
import Calculator from '../img/calculator.svg'
import PoolBox from "./PoolBox";
import {toast} from "react-toastify";

class Landing extends Component {

    render() {



        return (
            <div className="row rowLanding">
                <div className="col-12 rowLandingCol">
                    <div className="row rowLandingRow">
                        <div className="col-12 text-center colLandingHeader">
                            <div className="colLandingHeaderContainer">
                                <h1 id="landingTitle">SuperLion has released its V2</h1>
                            </div>
                        </div>
                        <div className="col-12 text-center colLandingText">
                            <div className="colLandingTextContainer">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                        <div className="col-6 text-center colLandingButtonV1Container">
                            <div className="colLandingButtonV1ContainerContainer">
                                <a href="" className="btn btn-primary buttonContinueV1">Continue on V1</a>
                            </div>
                        </div>
                        <div className="col-6 text-center colLandingButtonV2Container">
                            <div className="colLandingButtonV2ContainerContainer">
                                <a href="" className="btn btn-primary buttonGoToXlionV2">Go to XLion V2</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Landing;
