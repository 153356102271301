import React, { Component } from 'react'
import Calculator from '../img/calculator.svg'
import PoolBox from "./PoolBox";
import {toast} from "react-toastify";

class ChangeTokens extends Component {

    render() {



        return (
            <div className="row rowChangeTokens">
                <div className="col-12 text-center colChangeTokens">
                    <h1 id="ChangeTokensTitle">Swap your <span className="slionName">SLION</span> for <span className="xlionName">XLION</span></h1>

                </div>
                <div className="col-12 text-center colChangeTokensAlert">
                    <p>
                        <span className="alertText">ALERT!</span> Obtendrás tus XLION en el bloque 913912313
                    </p>
                    <p>
                        aproximadamente 1 semana después del inicio del MasterChef de XLION, para evitar una rápida inflacción.
                    </p>
                </div>
                <div className="col-4 text-center colSlionValue">
                    <div className="slionQty">
                        <span className="slionQtyValue">25</span> <span className="slionQtySimbol">Slion</span>
                    </div>
                    <div className="slionUSD">
                        <span className="slionQtyValue">0.25</span> <span className="slionQtySimbol">$</span>
                    </div>
                </div>

                <div className="col-4 text-center colChangeFor">
                    change for
                </div>

                <div className="col-4 text-center colXlionValue">
                    <div className="xlionQty">
                        <span className="xlionQtyValue">25</span> <span className="xlionQtySimbol">Xlion</span>
                    </div>
                    <div className="xlionUSD">
                        <span className="xlionQtyValue">0.25</span> <span className="xlionQtySimbol">$</span>
                    </div>
                </div>

                <div className="col-12 text-center colYouEarn">
                    <span className="youEarnText">You earn</span>
                    <span className="youEarnPercent">873,297.23 %</span>
                </div>

                <div className="col-12 text-center colXlionAvailable">
                    <span className="colXlionAvailableQty">75.000</span> <span className="xlionName">XLION</span> available for change of <span className="colXlionAvailableQtyOf">750.000</span>
                </div>

                <div className="col-6 text-center colSlionDeposit">
                    <div className="col-12 colSlionDepositText">
                        You deposited <span className="colSlionDepositQty">79,333.2</span> <span className="slionName">Slion</span>
                    </div>
                    <div className="col-12 colSlionDepositCointanerInput">
                        <input
                            className="tokenInput"
                            type="number"
                            value="2222"
                        />
                        <div className="maxButtonContainer">
                            <a
                                className="maxButton btn btn-primary"
                                href=""
                            >MAX</a>
                        </div>
                    </div>
                    <div className="col-12 colSlionDepositCointanerButton">
                        <button
                            className="depositSlionButton btn btn-block btn-primary"
                        >
                            Deposit Slion's
                        </button>
                    </div>

                    <div className="col-12 colSlionDepositCointanerAlert">
                        ALERT! No podrás retirar tus slion depositados, estos seran quemados automaticamente al dispositarlos, para realizar el cambio.
                    </div>

                </div>

                <div className="col-6 text-center colXlionForClaim">
                    <div className="col-12 colXlionForClaimText">
                        <span className="colXlionForClaimQty">13</span> <span className="xlionName">Xlion</span> for claim
                    </div>
                    <div className="col-12 colXlionForClaimCointanerButton">
                        <button
                            className="claimXlionButton btn btn-block btn-primary"
                        >
                            Claim Xlion's!
                        </button>
                    </div>
                    <div className="col-12 colXlionForClaimCointanerAlert">
                        Faltan 33.872 bloques para poder reclamar tus XLION's
                        <br />
                        <a href="">Ver cuenta atrás</a>
                    </div>
                </div>

            </div>
        );
    }
}

export default ChangeTokens;
